
    var riot = require('riot')
    
riot.tag2('ecommercebar', '<div class="btn-group basket-group"> <button onclick="{showcurrency}" class="btn btn-default btn-sm currency {currencyclass}">{currencies[currency]} <ul class="dropdown-menu"> <li><a href="#" onclick="{setCurrency.bind(this,\'GBP\')}">£</a></li> <li><a href="#" onclick="{setCurrency.bind(this,\'USD\')}">$</a></li> <li><a href="#" onclick="{setCurrency.bind(this,\'EUR\')}">€</a></li> </ul> </button> <button riot-tag="popup-basket" checkout-url="/system/checkout/" currency="{currency}" onclose="{basketclose}" class="btn btn-default btn-sm basket"> <span class="hidden-xs">{parent.total || ⁗0.00⁗}</span><span class="fa fa-shopping-basket"></span></button> </div>', '', '', function(opts) {
var xhr;

xhr = require("../libs/xhr.coffee");

this.on("mount", function() {
  this.currencyclass = "";
  this.currency = opts.currency || "GBP";
  this.currencies = {
    "GBP": "£",
    "USD": "$",
    "EUR": "€"
  };
  return this.getBasket();
});

this.getBasket = (function(_this) {
  return function(cback) {
    return xhr("GET", "/duocms/api/basket?currency=" + _this.currency, null, function(res) {
      _this.total = res.body.data.total.toFixed(2);
      if (cback) {
        cback();
      }
      return _this.update();
    });
  };
})(this);

this.showcurrency = (function(_this) {
  return function() {
    if (_this.currencyclass === "") {
      _this.currencyclass = "show";
      setTimeout((function() {
        return document.addEventListener("click", _this.hidecurrency);
      }), 500);
    }
    return _this.update();
  };
})(this);

this.hidecurrency = (function(_this) {
  return function() {
    _this.currencyclass = "";
    document.removeEventListener("click", _this.hidecurrency);
    return _this.update();
  };
})(this);

this.setCurrency = (function(_this) {
  return function(curr) {
    if (_this.currency !== curr) {
      _this.currency = curr;
      return _this.getBasket(function() {
        _this.updateCheckout();
        return _this.updateBuyButtons();
      });
    }
  };
})(this);

this.basketclose = (function(_this) {
  return function() {
    return _this.getBasket();
  };
})(this);
});
    
  