'use strict';

var $, clists, jQuery, riot, searchbutton, searchform, topbutton;

window.riot = riot = require('riot');

require('riot-kit/lib/slideshow');

require("riot-kit/lib/img_loader");

require('riot-kit/lib/form');

require('riot-kit/lib/twitter');

require('riot-kit/lib/modal');

require('riot-kit/lib/form');

require('riot-kit/lib/ecommerce');

require('riot-kit/lib/pagination');

require('riot-kit/lib/dateformat');

require('riot-kit/lib/booking-calendar');

require('riot-kit/lib/dropdown');

require('riot-kit/lib/gmap');

require('riot-kit/lib/auth/forgot-password');

require('riot-kit/lib/social-share');

require('smoothscroll-polyfill').polyfill();

$ = jQuery = window.jQuery = window.$ = require("jquery");

require('owl.carousel');

require('./tags/loginbar.tag');

require('./tags/ecommercebar.tag');

require('./tags/navigation.tag');

require('./tags/mydetails.tag');

require('./tags/mypassword.tag');

require('./tags/signup.tag');

require('./tags/tags-input.tag');

require('./tags/comments_input.tag');

require('./tags/comments_output.tag');

require('./tags/recaptcha.tag');

require('./tags/pricetag.tag');

require('./libs/slideshow.coffee');

require('./tags/duomap.tag');

require('./tags/recaptcha.tag');

riot.mount('*');

document.addEventListener('duocms:updated', function () {
  return riot.mount('slideshow,gmap');
});

topbutton = document.querySelector('.topbutton');

topbutton.addEventListener('click', function () {
  return document.querySelector('#page-top').scrollIntoView({
    behavior: 'smooth'
  });
});

clists = document.querySelectorAll('.dl_panel');

searchbutton = document.querySelector('.searchactivate');

searchform = document.querySelector('.searchform');

searchbutton.addEventListener('click', function (e) {
  if (searchform.className.indexOf('open') > -1) {
    return searchform.className = searchform.className.replace(/ ?open ?/, '');
  } else {
    return searchform.className = searchform.className.replace(/ ?open ?/, '') + ' open';
  }
});

// # mobile nav stuff
// mmutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver
// mo = new mmutationObserver (mutations)->
//   m = 0
//   # mutations.forEach (mutation)->
//   while m < mutations.length
//     mutation = mutations[m]
//     if mutation.type == "attributes" && mutation.target.className.match(/navshowing/)
//       [].slice.call(document.querySelectorAll(".navbar-nav li")).forEach (subnav)->
//         subnav.addEventListener 'click',(e)->
//           if subnav.getAttribute('riot-tag') == "dropdown" && subnav.className.match(/open/)
//             console.log "subnav click"
//             e.preventDefault()
//             subnav.querySelector('a,i').addEventListener 'click',(e)->
//               window.location.href = subnav.querySelector('a').href
//             subsecs = subnav.querySelectorAll('.dropdown-menu a')
//             if subsecs.length > 0
//               [].slice.call(subsecs).map (sec)=>
//                 sec.addEventListener 'click',(e)->
//                   window.location.href = sec.href
//     m++

// mo.observe(document.querySelector('body'),{attributes:true})
window.onload = function () {
  var updateImage;
  updateImage = function updateImage(e) {
    var active, img, prev, prnt;
    img = e.currentTarget.querySelectorAll('.owl-item .item')[e.item.index].getAttribute('data-img');
    if (img === "undefined") {
      img = '';
    }
    prnt = $(e.currentTarget).closest('.band').find('.fullimage');
    active = prnt.children('.owlimg-active');
    prev = prnt.children('.owlimg-prev');
    prev.removeClass('fadeout');
    active.css('background-image', 'url(' + img + ')');
    active.addClass('fadein');
    if (!img || typeof img === 'undefined' || img === 'undefined') {
      prev.addClass('fadeout');
    }
    return setTimeout(function () {
      prev.css('background-image', 'url(' + img + ')');
      return active.removeClass('fadein');
    }, 1500);
  };
  return [].forEach.call(clists, function (clist) {
    $(clist).addClass('owl-carousel');
    $(clist).owlCarousel({
      items: 1,
      autoplay: true,
      autoplayTimeout: 6000,
      loop: true,
      dots: true,
      autoplayHoverPause: true,
      onInitialized: updateImage,
      dotsContainer: $(clist).closest('.band').find('.owl-dots-outer')
    });
    $(clist).on('changed.owl.carousel', updateImage);
    return $(clist).trigger('stop.owl.autoplay');
  });
};