"use strict";

var addControls, addPagers, gotoSlide, moveNext, movePrev, _nextSlide, pagerSetActive, _prevSlide, preventDoubleAnimation, ref;

addControls = function addControls() {
  return [].slice.call(document.querySelectorAll(".duoslideshow")).forEach(function (slideshow) {
    var next, prev;
    next = document.createElement("div");
    next.className = "next fa fa-chevron-right";
    next.addEventListener("click", function () {
      return moveNext(slideshow);
    });
    slideshow.appendChild(next);
    prev = document.createElement("div");
    prev.className = "prev fa fa-chevron-left";
    prev.addEventListener("click", function () {
      return movePrev(slideshow);
    });
    return slideshow.appendChild(prev);
  });
};

addPagers = function addPagers() {
  return [].slice.call(document.querySelectorAll(".duoslideshow")).forEach(function (slideshow) {
    var pager, slides;
    pager = document.createElement("div");
    pager.className = "pager";
    slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"));
    slides.forEach(function (slide, idx) {
      var pagerButton;
      pagerButton = document.createElement("div");
      pagerButton.className = "pager-button";
      pagerButton.addEventListener("click", function () {
        return gotoSlide(slideshow, idx);
      });
      return pager.appendChild(pagerButton);
    });
    slideshow.appendChild(pager);
    return pagerSetActive(slideshow);
  });
};

pagerSetActive = function pagerSetActive(slideshow) {
  var pagerButtons, slides;
  slides = [].slice.call(slideshow.querySelectorAll(".duoslide"));
  pagerButtons = [].slice.call(slideshow.querySelectorAll(".pager-button"));
  return pagerButtons.forEach(function (b, idx) {
    if (slides[idx].className.indexOf("active") > -1 && b.className.indexOf("active") === -1) {
      b.classList.add("active");
    }
    if (slides[idx].className.indexOf("active") === -1 && b.className.indexOf("active") > -1) {
      return b.classList.remove("active");
    }
  });
};

_nextSlide = function nextSlide(active) {
  var newactive;
  newactive = active.nextElementSibling;
  if (newactive && newactive.className.indexOf("duoslide") > -1) {
    return newactive;
  }
  if (newactive) {
    return _nextSlide(newactive);
  }
};

_prevSlide = function prevSlide(active) {
  var newactive;
  newactive = active.previousElementSibling;
  if (newactive && newactive.className.indexOf("duoslide") > -1) {
    return newactive;
  }
  if (newactive) {
    return _prevSlide(newactive);
  }
};

preventDoubleAnimation = function preventDoubleAnimation(slideshow) {
  if (slideshow.className.indexOf("active") > -1) {
    return true;
  }
  slideshow.classList.add("active");
  setTimeout(function () {
    return slideshow.classList.remove("active");
  }, 1000);
  return false;
};

gotoSlide = function gotoSlide(slideshow, idx) {
  var active, slides;
  if (preventDoubleAnimation(slideshow)) {
    return;
  }
  console.log("gotoSlide", slideshow, idx);
  slides = [].slice.call(slideshow.querySelectorAll(".duoslide"));
  active = slideshow.querySelector(".duoslide.active");
  active.classList.remove("active");
  slides[idx].classList.add("active");
  return pagerSetActive(slideshow);
};

moveNext = function moveNext(slideshow) {
  var active, newactive, slides;
  if (preventDoubleAnimation(slideshow)) {
    return;
  }
  slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"));
  if (slides != null ? slides.length : void 0) {
    active = slideshow.querySelector(".duoslideshow .duoslide.active"); // get current active
    if (active) {
      newactive = _nextSlide(active);
      active.classList.remove("active"); // remove active class
    }
    if (newactive) {
      newactive.classList.add("active");
    } else {
      slides[0].classList.add("active");
    }
  }
  return pagerSetActive(slideshow);
};

movePrev = function movePrev(slideshow) {
  var active, newactive, slides;
  if (preventDoubleAnimation(slideshow)) {
    return;
  }
  slides = [].slice.call(slideshow.querySelectorAll(".duoslideshow .duoslide"));
  if (slides != null ? slides.length : void 0) {
    active = slideshow.querySelector(".duoslideshow .duoslide.active"); // get current active
    if (active) {
      newactive = _prevSlide(active);
      active.classList.remove("active"); // remove active class
    }
    if (newactive) {
      newactive.classList.add("active");
    } else {
      slides[slides.length - 1].classList.add("active");
    }
  }
  return pagerSetActive(slideshow);
};

if (!((ref = window.location.search) != null ? ref.match(/editmode/) : void 0)) {
  addControls();
  addPagers();
  setInterval(function () {
    return [].slice.call(document.querySelectorAll(".duoslideshow")).forEach(function (slideshow) {
      return moveNext(slideshow);
    });
  }, 4000);
}