"use strict";

module.exports = function (method, url, data, cback) {
  var req;
  req = new XMLHttpRequest();
  req.open(method, url, true);
  req.setRequestHeader("Content-type", "application/json");
  req.setRequestHeader("x-csrf-token", window.csrf);
  req.onreadystatechange = function () {
    var res;
    if (req.readyState === 4) {
      try {
        res = JSON.parse(req.responseText);
      } catch (error) {
        res = "";
      }
      return cback({
        status: req.status,
        body: res
      });
    }
  };
  return req.send(JSON.stringify(data));
};